@import 'src/styles/imports';

.planner {
  background-color: $blue-500;
  height: 100vh;
  overflow: visible;
  flex: 1 0 auto;
  transition: all 0.5s ease-in-out;
}

.plannerContent {
  padding-top: 60px;
  height: 100%;
  overflow: auto;
  color: #fff;
}

// edit mode

.editMode {
  background-color: $blue-700;
  border: 1px solid #fff;
  margin: 20px;
  border-radius: 4px;
}

.notEditMode {
  background-color: $blue-600;
}

.editModeHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 52px;
  > h3 {
    width: 100%;
    color: $white;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    border: 0;
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
    svg {
      stroke: $white;
    }
  }
}

.inEditButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  row-gap: 10px;

  .confirmationButton {
    width: fit-content;
    background-color: #fff;
    border: 0;
    border-radius: 500px;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 20px;
    color: $blue-500;
    cursor: pointer;
  }
  .cancelButton {
    background-color: transparent;
    border: 0;
    padding: 5px 20px;
    color: $white;
    cursor: pointer;
  }
}

// change summary

.changeSummary {
  padding: 20px;
}
