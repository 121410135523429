@import 'src/styles/imports';

section {
  .sectionContents {
    padding: 20px;
    overflow-wrap: break-all;
    width: 100%;
    border-bottom: 1px solid $grey-100;

    a {
      text-decoration: none;
      color: $blue-500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.courseProgress {
    > h2 {
      font-size: 1rem;
      margin: 0 0 20px;
    }
  }
}

.keyBlob {
  display: inline-block;
  height: 10px;
  width: 1rem;
  margin: 0 5px 0 0;
  border-radius: 8px;
}

.accordionHeader {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  h3 {
    flex-grow: 1;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
  }

  //Credit Points Tag
  > div {
    margin: 0;
  }
  .secondary {
    color: $grey-800;
    font-size: 14px;
  }

  svg {
    justify-self: flex-end;
    flex: 0 0 auto;
  }
}

.note {
  margin: 0 20px 20px 20px;
  color: $grey-900;
  white-space: pre-wrap;

  .noteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    .type {
      font-weight: 500;
    }
  }
}

.priorContent {
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  > div {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    align-self: start;
  }
  .institution {
    > span {
      font-weight: 500;
    }
  }

  .comment {
  }
}

.priorStatusLabel {
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI';
  font-size: 16px;
  font-weight: 500;
  color: $purple-priorstudy;
  // white-space: nowrap;
}

.showMore {
  color: $blue-500;
  width: 100%;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
