@import 'src/styles/imports';

.tag {
  &.condense {
    padding: 1px 4px;
  }

  padding: 4px 10px;
  line-height: 1;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 4px 4px 0;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  max-width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    stroke: currentColor;
    height: 1rem;
    width: 1rem;
    margin-left: 5px;
  }

  &.inActive {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: $grey-300 !important;
  }
}

.remove {
  cursor: pointer;
}

.warning {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;

  svg {
    stroke: $white;
  }
}

.count {
  display: inline-flex;
  justify-content: center;
  // border: 1px solid $blue-700;
  border-radius: 4px;
  min-width: 16px;
  margin: 2px;
  padding: 1px 4px;
  background-color: rgba(#fff, 0.2);
}

.red {
  color: $red-attention;

  svg {
    fill: none;
    stroke: $red-attention !important;
  }
}
