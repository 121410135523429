@import 'src/styles/imports';

.plans {
  padding: 80px 20px 20px;
  height: 100vh;
  overflow: auto;
}

.container {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.plan {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $grey-100;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 1px rgba(#000, 0.1);
  animation: fadeIn 0.5s 1 forwards ease-in-out;
  opacity: 0;
  transform: translate(0, -10px);
  display: flex;
  overflow: auto;

  &:hover {
    // box-shadow: 0 6px 1px (#d6d6d6);
    box-shadow: 0 6px 12px (#d6d6d6);
  }
}

.body {
  //flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $grey-900;
  padding: 20px;
  overflow: auto;
}

.content {
  //width: auto;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 5px;

    .viewed {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  label {
    color: $grey-800;
  }

  h2 {
    margin: 0;
    font-size: 1rem;
  }
}

.preview {
  margin: 20px;
  // min-height: 0;
  // max-height: 100%;
  overflow: auto;
  //position: relative;
  //top: 0;
  // left: 50%;
  flex: 0 0 auto;
  width: 250px;
  height: 250px;
  // min-width: 33%;
  // max-width: 350px;
}

.aos {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.meta {
  //border-top: 1px solid $grey-100;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;

  label {
    color: $grey-800;
  }

  .current {
    color: $blue-800;
    font-weight: 700;
  }
}

.createNewPlan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-top: 20px;
  padding: 40px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  animation: fadeIn 1s 0.5s 1 forwards ease-in-out;
  opacity: 0;
  transform: translate(0, -10px);
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: $blue-tinted-100;
  }

  .icon {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    // background-color: $blue-tinted-100;
    transition: all 0.3s ease-in-out;
    svg {
      height: 45px;
      width: 45px;
      stroke: $blue-600;
    }
  }

  .text {
    font-size: 1rem;
    margin-top: 10px;
    color: $blue-500;
  }
}

.red {
  color: $red-attention;

  svg {
    stroke: $red-attention !important;
  }
}

// select enrolment

@keyframes dropIntoCentre {
  0% {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 10px));
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.selectEnrolment {
  position: fixed;
  z-index: 9;
  left: 50%;
  top: 40%;
  opacity: 0;
  transform: translate(-50%, calc(-50% - 10px));
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid $grey-100;
  box-shadow: 0 4px 16px rgba($black, 0.1);
  animation: dropIntoCentre 0.3s 1 forwards ease-in-out;

  h2 {
    font-size: 1rem;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: 0;
      background: none;
      font-size: 14px;
      padding: 0;
      color: $grey-800;
      cursor: pointer;

      &:hover {
        color: $blue-500;
        text-decoration: underline;
      }
    }
  }

  .name {
    text-transform: capitalize;
  }

  .buttonContainer {
    text-align: center;
    margin-top: 20px;

    button {
      margin: 0 5px;
      background-color: #fff;
      border: 0;
      border-radius: 500px;
      padding: 6px 10px;
      color: $grey-800;
      cursor: pointer;
      font-weight: 400;

      &:hover {
        background-color: $grey-100;
      }

      &.confirmDelete {
        background-color: $red-attention;
        color: #fff;
        font-weight: 700;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  ul {
    padding: 0;
    margin: 20px 0 0;
    list-style: none;

    li {
      padding: 4px 10px;
      border-left: 2px solid $grey-200;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
        background-color: $blue-tinted-000;
      }

      h3 {
        margin: 0;
        font-size: 1rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          font-weight: 400;
          font-size: 14px;
          color: $grey-200;
        }
      }

      &.enrolled {
        border-color: $green-success;

        h3 {
          label {
            color: $green-success;
          }
        }
      }

      div {
        font-size: 14px;
      }
    }
  }
}
